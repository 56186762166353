<template>
    <div>
        <b-card title="Dobrodošli v Imprion Blagajni admin odseku">
            Upravljanje Imprion blagajne
        </b-card>
    </div>
</template>

<script>
    import { BCard } from 'bootstrap-vue'
    export default {
        components: {
            BCard
        }
    }
</script>

<style>

</style>
